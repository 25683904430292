import(/* webpackMode: "eager", webpackExports: ["CardMyNetWorth"] */ "/working_dir/prd/application/app/_ui/components/Dashboard/CardMyNetWorth.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardMyTeam"] */ "/working_dir/prd/application/app/_ui/components/Dashboard/CardMyTeam.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardPlanning"] */ "/working_dir/prd/application/app/_ui/components/Dashboard/CardPlanning.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardPortfolioPerformance"] */ "/working_dir/prd/application/app/_ui/components/Dashboard/CardPortfolioPerfomance.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardQuickActions"] */ "/working_dir/prd/application/app/_ui/components/Dashboard/CardQuickActions.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardRecentActivity"] */ "/working_dir/prd/application/app/_ui/components/Dashboard/CardRecentActivity.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardRecentDocuments"] */ "/working_dir/prd/application/app/_ui/components/Dashboard/CardRecentDocuments.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WelcomeHeader"] */ "/working_dir/prd/application/app/_ui/components/Dashboard/WelcomeHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/working_dir/prd/application/app/(secured)/dashboard-kpis.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageRedirects"] */ "/working_dir/prd/application/app/(secured)/page-redirects.tsx");
