"use client";

import * as userProfileService from "#app/_api/user-profile-service";
import { logError } from "#app/lib/logger";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  type CardRootProps,
} from "#ui/card";
import { Icon } from "#ui/icon";
import { Anchor, LinkButton } from "#ui/link";
import { Logo } from "#ui/logo";
import { H2, H3 } from "#ui/typography";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { PendingCardContent } from "../cards/PendingCard";
import ErrorBanner from "../errors/error-banner";
import { useNavRoutes } from "../headers/use-nav-routes";

//TODO This is duped
function formatAddress(
  addressLine1: string | null,
  addressLine2: string | null,
  city: string | null,
  state: string | null,
  zip: string | null,
): string {
  const formattedLines1And2 = [addressLine1, addressLine2]
    .filter(Boolean)
    .join(" ");
  return [formattedLines1And2, city, state, zip].filter(Boolean).join(", ");
}

export function CardMyTeam(props: CardRootProps) {
  const routes = useNavRoutes();

  const { data, error, isError, isPending, isSuccess } = useQuery(
    userProfileService.useFaInfoQueryOptions(),
  );

  const branchAddress = useMemo(() => {
    return data
      ? formatAddress(
          data.branchAddress1,
          data.branchAddress2,
          data.branchCity,
          data.branchState,
          data.branchPostalCode,
        )
      : null;
  }, [data]);

  if (isError) {
    logError(error);
  }

  return (
    <Card {...props}>
      <CardHeader palette="gray">
        <H2 size="h6" className="flex items-center gap-10px">
          <Icon name="baird-wealth-strategy-webinar" size="baird-md" />
          <span>My Team</span>
        </H2>
      </CardHeader>
      <CardContent>
        {isPending ? <PendingCardContent className="md:min-h-fit" /> : null}

        {isError ? <ErrorBanner error="An error occurred." /> : null}

        {isSuccess ? (
          <div className="gap-4 grid grid-cols-5">
            <div className="col-span-1">
              <Logo size="xs" layout="mark-only"></Logo>
            </div>
            <div className="col-span-4 last:*:mt-5px">
              <H3 size="h6" className="break-all">
                {data.branchDisplayName}
              </H3>
              <div>{data.branchPhone}</div>
              {data.branchUrl ? (
                <div>
                  <Anchor
                    href={data.branchUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="break-all"
                  >
                    {data.branchUrl}
                  </Anchor>
                </div>
              ) : null}
              <div>{branchAddress}</div>
              <div>
                <LinkButton
                  href={routes.DocumentsReceived.path}
                  variant="outline"
                  palette="neutral"
                >
                  <Icon name="ms-upload-filled" />
                  <span>Upload document</span>
                </LinkButton>
              </div>
            </div>
          </div>
        ) : null}
      </CardContent>
      <CardFooter palette="white">
        <LinkButton className="w-full" href={routes.MyTeam.path!}>
          See all team members
        </LinkButton>
      </CardFooter>
    </Card>
  );
}
