import { useAccountNicknameLookup } from "#app/(unauthorized)/authentication/useAccountNicknameLookup";
import { cx } from "#ui/style.utils";

export interface AccountProps {
  accountNumber: string | null | undefined | number;
  className?: string;
  multiline?: boolean;
  nameOverride?: string;
}

export function Account({
  accountNumber,
  className,
  multiline,
  nameOverride,
}: AccountProps) {
  const nicknameLookup = useAccountNicknameLookup();

  if (typeof accountNumber == "number") {
    accountNumber = `00000000${accountNumber}`;
    accountNumber = accountNumber.substring(accountNumber.length - 8);
  }

  if (accountNumber == null || accountNumber == undefined) return "N/A";

  var nickname = nameOverride ?? (nicknameLookup[accountNumber] || "");
  if (nickname == accountNumber) nickname = "";

  const l = accountNumber.length;
  const actStr = accountNumber.substring(l - 4);

  if (multiline) {
    return (
      <span className={cx("text-sm *:block last:*:text-shade-70", className)}>
        <span>{nickname}</span> <span>[Account type] &hellip;{actStr}</span>
      </span>
    );
  }

  return (
    <span className={cx("block text-sm", className)}>
      {nickname} &hellip;{actStr}
    </span>
  );
}
