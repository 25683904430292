"use client";

import { AnchorSecuritySymbol } from "#app/(secured)/documents/_shared/AnchorSecuritySymbol";
import {
  portfolioQueryKeys,
  type AccountActivity,
} from "#app/_api/portfolio-service";
import { Card, CardContent, CardHeader, type CardRootProps } from "#ui/card";
import {
  CellUnavailable,
  Column,
  DataGrid,
  defaultDataGridProps,
} from "#ui/data-grid";
import { Icon } from "#ui/icon";
import { LinkButton } from "#ui/link";
import { H2, P } from "#ui/typography";
import { useQuery } from "@tanstack/react-query";
import Image from "next/image";
import { ErrorCardContent } from "../cards/ErrorCard";
import { PendingCardContent } from "../cards/PendingCard";
import { Account } from "../textFormaters/Account";

/**
 * Only show for Clients, 3rd Parties, PAM
 * Visibility handled by different dashboard routes.
 */
export function CardRecentActivity(props: CardRootProps) {
  const { data, isError, isPending, isSuccess } = useQuery({
    ...portfolioQueryKeys.recentActivity({
      activitySortValue: "Date",
      sortDirection: "Descending",
    }),
    // TODO: how long? staleTime: 5 * 60 * 1000, // 5min
  });

  const isEmpty = isSuccess && data.transactions.length === 0;
  const isFound = isSuccess && data.transactions.length > 0;
  const recentActivity = data?.transactions.slice(0, 5) ?? [];

  return (
    <Card {...props}>
      <CardHeader palette="white" className="flex items-center justify-between">
        <H2 size="h6" className="flex items-center gap-10px">
          <Icon name="baird-list" size="baird-md" />
          <span>Recent Activity</span>
        </H2>
        <LinkButton href="/investments/activity" variant="link">
          <span>See all</span>
          <Icon name="ms-navigate-next" />
        </LinkButton>
      </CardHeader>
      <CardContent className={isFound ? "p-0 pb-12px" : undefined}>
        {isPending ? <PendingCardContent className="md:min-h-fit" /> : null}
        {isError ? <ErrorCardContent className="md:min-h-fit" /> : null}
        {isEmpty ? <CardRecentActivityEmpty /> : null}
        {isFound ? <CardRecentActivityFound data={recentActivity} /> : null}
      </CardContent>
    </Card>
  );
}

function CardRecentActivityEmpty() {
  return (
    <div className="space-y-30px text-center">
      <Image
        alt=""
        src="/Images/image-empty-state.png"
        priority
        width="211"
        height="171"
        className="mx-auto"
      />
      <P size="h6">You have no recent activity.</P>
    </div>
  );
}

function CardRecentActivityFound({
  data,
}: {
  data: AccountActivity["transactions"];
}) {
  return (
    <DataGrid
      {...defaultDataGridProps}
      dataSource={data}
      noDataText="No results found"
    >
      <Column
        name="description"
        caption="Description"
        dataField="desc"
        dataType="string"
        width={140}
      />
      <Column
        name="securityId"
        caption="Security ID"
        dataField="instrument.symbol"
        dataType="string"
        cellRender={(templateData: { data: (typeof data)[number] }) =>
          templateData.data.instrument?.symbol ? (
            <AnchorSecuritySymbol
              symbol={templateData.data.instrument.symbol}
            />
          ) : (
            <CellUnavailable />
          )
        }
      />
      <Column
        name="date"
        caption="Date"
        dataField="tradeDate"
        dataType="date"
        defaultSortOrder="desc"
        format="LLL d, yyyy"
      />
      <Column
        name="account"
        caption="Account"
        dataField="accountNumber"
        dataType="number"
        cellRender={(templateData: { data: (typeof data)[number] }) => (
          <Account accountNumber={templateData.data.accountNumber} />
        )}
        alignment="left"
      />
      <Column
        name="amount"
        caption="Amount"
        dataField="quantity"
        dataType="number"
        cellRender={(templateData: { data: (typeof data)[number] }) =>
          formatNumberWithoutRounding(templateData.data.quantity, 4)
        }
        alignment="right"
      />
      <Column
        name="price"
        caption="Price"
        dataField="price"
        dataType="number"
        format="$,##0.00"
        alignment="right"
      />
      <Column
        name="netAmount"
        caption="Net Amount"
        dataField="amount"
        dataType="number"
        format="$,##0.00"
        alignment="right"
      />
    </DataGrid>
  );
}

/**
 * Formats similar to Intl.NumberFormat("en-US"):
 * - commas on larger significant digits
 * - limits maximum fraction digits
 * - no rounding on fraction digits
 * - no trailing zeros on either digits
 *
 * Usage:
 * - `formatNumberWithoutRounding(1234.0); // => "1,234"`
 * - `formatNumberWithoutRounding(1234.90909); // => "1,234"`
 * - `formatNumberWithoutRounding(1234.99999); // => "1,234"`
 *
 * Usage with `maximumFractionDigits` set:
 * - `formatNumberWithoutRounding(1234.0, 4); // => "1,234"`
 * - `formatNumberWithoutRounding(1234.90909, 4); // => "1,234.909"`
 * - `formatNumberWithoutRounding(1234.99999, 4); // => "1,234.9999"`
 */
function formatNumberWithoutRounding(
  value: number | unknown,
  maximumFractionDigits = 0,
) {
  if (typeof value !== "number") {
    return "";
  }
  // format floats
  if (value % 1 !== 0) {
    const [a, b] = String(value).split(".");
    return [
      new Intl.NumberFormat("en-US").format(Number(a)),
      b?.slice(0, maximumFractionDigits).replace(/([1-9])0+$/g, "$1") ?? null,
    ]
      .filter(Boolean)
      .join(".");
  }
  // format integers
  return new Intl.NumberFormat("en-US").format(value);
}
